<template>
	<Modal
		class="view-achieve-modal"
		:value="visible"
		:fullscreen="fullscreen"
		:mask-closable="false"
		:styles="{ top: fullscreen ? 0 : '60px' }"
		:footer-hide="true"
		@on-cancel="onCancel"
		width="1000"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="view-achieve-modal-header">
			<h2>查看研究成果</h2>
			<a href="javascript:;" class="fullscreen" @click="handleFullScreen">
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<div>
			<fill-form
				v-if="visible"
				:updateId="updateId"
				:isDisabled="true"
			></fill-form>
		</div>
	</Modal>
</template>

<script>
import FillForm from "@/views/Project/AchievementFormFillIn/FillForm.vue"

export default {
	name: "viewPro",
	props: ["visible", "updateId"],
	components: {
		FillForm
	},
	data() {
		return {
			loading: false,
			fullscreen: false // 是否全屏弹出框
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		},
		handleFullScreen() {
			// 全屏查看
			this.fullscreen = !this.fullscreen
		}
	}
}
</script>

<style lang="less">
.view-achieve-modal {
	.view-achieve-modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
</style>
